import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getAgents = createAction('agents/GET_AGENTS');

const agentsSlice = createSlice({
  initialState: initialStore.agentsPage,
  name: 'agentsPage',
  reducers: {
    removeAgents: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setAgents: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    updateAgents: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const { removeAgents, setAgents, setLoading, updateAgents } =
  agentsSlice.actions;

export const { reducer } = agentsSlice;
