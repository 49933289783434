import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class BankService extends BaseService {
  getBanks = async (params) => {
    const { data } = await http({
      params,
      url: this.endpoint
    });
    return data;
  };

  getOptionsList = (params) => {
    return http({
      params,
      url: `${this.endpoint}/`
    }).then(({ data }) => ({
      next: data.next,
      result: data.result?.map((el) => {
        return {
          label: el.name,
          value: el.id
        };
      })
    }));
  };

  constructor() {
    super('/bank');

    if (!BankService.instance) {
      BankService.instance = this;
    }
    return BankService.instance;
  }
}

const instance = new BankService();
Object.freeze(instance);

export default instance;
