import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import AgentService from '#services/AgentService';

import { getAgents, setAgents, setLoading } from './';

function* getAgentsList(data) {
  try {
    yield put(setLoading(true));
    const agents = yield call(AgentService.getList, data?.payload);
    yield put(setAgents(agents));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });

    yield put(setAgents({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* AgentsSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getAgents.type, getAgentsList);
}

export default AgentsSaga;
