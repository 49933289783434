import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getDevices = createAction('devices/GET_DEVICES');

const devicesSlice = createSlice({
  initialState: initialStore.devicesPage,
  name: 'devicesPage',
  reducers: {
    removeDevice: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setDevices: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    updateDevice: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const { removeDevice, setDevices, setLoading, updateDevice } =
  devicesSlice.actions;

export const { reducer } = devicesSlice;
