import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import TariffService from '#services/TariffService';

import { getTariffs as getTariffsAction, setLoading, setTariffs } from './';

function* getTariffs(data) {
  try {
    const banks = yield call(TariffService.getList, data?.payload);
    yield put(setTariffs(banks));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setTariffs({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* TariffSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getTariffsAction.type, getTariffs);
}

export default TariffSaga;
