import { combineReducers } from '@reduxjs/toolkit';

import { reducer as agentsPageReducer } from './agentPage';
import { reducer as banksPageReducer } from './banksPage';
import { reducer as cardsPageReducer } from './cardsPage';
import { reducer as clientsPageReducer } from './clientsPage';
import { reducer as correctionsPageReducer } from './correctionsPage';
import { reducer as debtorsPageReducer } from './debtorsPage';
import { reducer as devicesPageReducer } from './devicesPage';
import { reducer as holdersPageReducer } from './holdersPage';
import { reducer as incomesPageReducer } from './incomesPage';
import { reducer as notificationsPageReducer } from './notificationsPage';
import { reducer as operatorsPageReducer } from './operatorsPage';
import { reducer as outcomesPageReducer } from './outcomePage';
import { reducer as tariffPageReducer } from './tariffPage';
import { reducer as transactionsPageReducer } from './transactionsPage';
import { reducer as userDataReducer } from './userData';
import { reducer as usersPageReducer } from './usersPage';

export default combineReducers({
  agentsPage: agentsPageReducer,
  banksPage: banksPageReducer,
  cardsPage: cardsPageReducer,
  clientsPage: clientsPageReducer,
  correctionsPage: correctionsPageReducer,
  debtorsPage: debtorsPageReducer,
  devicesPage: devicesPageReducer,
  holdersPage: holdersPageReducer,
  incomesPage: incomesPageReducer,
  notificationsPage: notificationsPageReducer,
  operatorsPage: operatorsPageReducer,
  outcomesPage: outcomesPageReducer,
  tariffsPage: tariffPageReducer,
  transactionsPage: transactionsPageReducer,
  userData: userDataReducer,
  usersPage: usersPageReducer
});
