import { BaseService } from '#services/BaseService';

class NotificationService extends BaseService {
  constructor() {
    super('/device-notification');
    if (!NotificationService.instance) {
      NotificationService.instance = this;
    }
    return NotificationService.instance;
  }
}

const instance = new NotificationService();
Object.freeze(instance);

export default instance;
