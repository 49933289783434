import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';
import { getFormattedCard } from '#utils/get-formatted-card';

class CardsService extends BaseService {
  constructor() {
    super('/card');
    if (!CardsService.instance) {
      CardsService.instance = this;
    }
    return CardsService.instance;
  }

  getOptionsList = (params) => {
    return http({
      params,
      url: `${this.endpoint}/`
    }).then(({ data }) => ({
      next: data.next,
      result: data.result?.map((el) => {
        return {
          data: el,
          label: `${el.device?.number} | ${el.bank?.name} ${
            el.cardNumber ? '| ' + getFormattedCard(el.cardNumber) : ''
          } ${el.iban ? '| ' + el.iban : ''}`,
          value: el.id
        };
      })
    }));
  };

  getCardStatement = async (params) => {
    const { id, ...rest } = params;
    const { data } = await http({
      params: rest,
      url: `${this.endpoint}/${id}/statement`
    });

    return data;
  };
}

const instance = new CardsService();
Object.freeze(instance);

export default instance;
