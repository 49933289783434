import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import HolderService from '#services/HolderService';

import { getHolders, setHolders, setLoading } from './';

function* getHoldersList(data) {
  try {
    yield put(setLoading(true));
    const agents = yield call(HolderService.getList, data?.payload);
    yield put(setHolders(agents));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setHolders({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* HoldersSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getHolders.type, getHoldersList);
}

export default HoldersSaga;
