export const getFormattedCard = (cardNumber) => {
  if (!cardNumber) {
    return '';
  }

  const formattedCardNumber = cardNumber
    .replace(/\s/g, '')
    .replace(/(.{4})/g, '$1 ');
  return formattedCardNumber.trim();
};
