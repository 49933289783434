import dayjs from 'dayjs';

import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class IncomeService extends BaseService {
  createCheckOperation = async (data) => {
    const { data: result } = await http({
      data,
      method: 'POST',
      url: `${this.endpoint}/check`
    });
    return result;
  };

  constructor() {
    super('/income-operation');
    if (!IncomeService.instance) {
      IncomeService.instance = this;
    }
    return IncomeService.instance;
  }

  exportToCSV = async (params) => {
    return await http({
      method: 'GET',
      params,
      responseType: 'blob',
      url: `${this.endpoint}/export-csv`
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const fileName = `export-income-${dayjs().format('YYYYDDMM-HHmmss')}.csv`;

      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
    });
  };
}

const instance = new IncomeService();
Object.freeze(instance);

export default instance;
