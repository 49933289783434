import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import StatsService from '#services/StatsService';
import { balanceFormatter } from '#utils/balance-formatter';

import classes from './Stats.module.scss';

export const Stats = () => {
  const { t } = useTranslation();
  const [statsInfo, setStatsInfo] = useState();
  const intervalId = useRef(null);
  const { user } = useSelector((state) => state.userData);

  const isAdmin = user.role === 'admin';
  const isOperator = user.operator?.id;

  const getStatsInfo = async () => {
    try {
      const data = await StatsService.getStats();
      setStatsInfo(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!isOperator) return;

    getStatsInfo();
    intervalId.current = setInterval(getStatsInfo, 5000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  return statsInfo ? (
    <div className={classes.statsContainer}>
      <div className={classes.statsCard}>
        <div>{t('Receipts')}</div>
        <div>
          {balanceFormatter(statsInfo?.income?.amount)} UAH |{' '}
          {statsInfo.income.operations}
        </div>
      </div>
      <div className={classes.statsCard}>
        <div>{t('Payouts')}</div>
        <div>
          {balanceFormatter(statsInfo?.outcome?.amount)} UAH |{' '}
          {statsInfo.outcome.operations}
        </div>
      </div>
      <div className={classes.statsCard}>
        <div>{t('Balance')}</div>
        <div>
          {balanceFormatter(statsInfo?.cardsInfo?.activeCardsBalance)} UAH
        </div>
      </div>
      {isAdmin && (
        <div className={classes.statsCard}>
          <div>
            {t('Balance')} {isAdmin && '(' + t('active') + ')'}
          </div>
          <div>
            {balanceFormatter(
              statsInfo?.cardsInfo?.activeOperatorsCardsBalance
            )}{' '}
            UAH
          </div>
        </div>
      )}
    </div>
  ) : null;
};
