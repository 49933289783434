export const operatorStatuses = {
  All: 'all',
  Income: 'income',
  Manually: 'manually',
  None: 'none',
  Outcome: 'outcome'
};

export const getOperatorStatus = (status, t) => {
  switch (status) {
    case operatorStatuses.All:
      return t('All directions');
    case operatorStatuses.Income:
      return t('Receiving');
    case operatorStatuses.Outcome:
      return t('Payouts');
    default:
      return t('Off');
  }
};
