import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getNotifications = createAction('notifications/GET_DEVICES');

const notificationsSlice = createSlice({
  initialState: initialStore.notificationsPage,
  name: 'notificationsPage',
  reducers: {
    removeNotification: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setNotifications: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    updateNotification: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const {
  removeNotification,
  setLoading,
  setNotifications,
  updateNotification
} = notificationsSlice.actions;

export const { reducer } = notificationsSlice;
