import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getCards = createAction('cards/GET_CARDS');

const cardsSlice = createSlice({
  initialState: initialStore.cardsPage,
  name: 'cardsPage',
  reducers: {
    removeCard: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setCards: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    updateCard: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const { removeCard, setCards, setLoading, updateCard } =
  cardsSlice.actions;

export const { reducer } = cardsSlice;
