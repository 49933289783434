import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getCorrections = createAction('correction/GET_CORRECTIONS');

const correctionSlice = createSlice({
  initialState: initialStore.correctionsPage,
  name: 'correctionPage',
  reducers: {
    removeCorrection: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setCorrections: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    updateCorrection: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const {
  removeCorrection,
  setCorrections,
  setLoading,
  updateCorrection
} = correctionSlice.actions;

export const { reducer } = correctionSlice;
