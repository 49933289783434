import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import DebtorsService from '#services/DebtorsService';

import { getDebtors, setDebtors, setLoading } from './';

function* getDebtorsList(data) {
  try {
    yield put(setLoading(true));
    const debtors = yield call(DebtorsService.getList, data?.payload);
    yield put(setDebtors(debtors));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setDebtors({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* DebtorsSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getDebtors.type, getDebtorsList);
}

export default DebtorsSaga;
