import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getDebtors = createAction('debtors/GET_DEBTS');

const debtorsSlice = createSlice({
  initialState: initialStore.debtorsPage,
  name: 'debtorsPage',
  reducers: {
    removeDebtor: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setDebtors: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    updateDebtor: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const { removeDebtor, setDebtors, setLoading, updateDebtor } =
  debtorsSlice.actions;

export const { reducer } = debtorsSlice;
