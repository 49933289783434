import { lazy } from 'react';

import { LayoutTypes, Urls } from '#constants';

export const ROUTES = [
  {
    component: lazy(() => import('./HomePage')),
    id: 1,
    layout: LayoutTypes.Base,
    path: Urls.HomePage,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./LoginPage')),
    id: 2,
    path: Urls.LoginPage,
    protected: false,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./OutcomePage')),
    id: 3,
    layout: LayoutTypes.Base,
    path: Urls.OutcomePage,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./IncomePage')),
    id: 4,
    layout: LayoutTypes.Base,
    path: Urls.IncomePage,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./UsersPage')),
    id: 5,
    layout: LayoutTypes.Base,
    path: Urls.UsersPage,
    protected: true,
    roles: ['admin']
  },
  {
    component: lazy(() => import('./ClientsPage')),
    id: 6,
    layout: LayoutTypes.Base,
    path: Urls.Clients,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./OperatorsPage')),
    id: 7,
    layout: LayoutTypes.Base,
    path: Urls.Operators,
    protected: true,
    roles: ['admin']
  },
  {
    component: lazy(() => import('./CardsPage')),
    id: 8,
    layout: LayoutTypes.Base,
    path: Urls.Cards,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./TariffsPage')),
    id: 9,
    layout: LayoutTypes.Base,
    path: Urls.Tariffs,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./BanksPage')),
    id: 10,
    layout: LayoutTypes.Base,
    path: Urls.Banks,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./DevicesPage')),
    id: 11,
    layout: LayoutTypes.Base,
    path: Urls.Devices,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./HoldersPage')),
    id: 12,
    layout: LayoutTypes.Base,
    path: Urls.Holders,
    protected: true,
    roles: ['admin']
  },
  {
    component: lazy(() => import('./AgentPage')),
    id: 13,
    layout: LayoutTypes.Base,
    path: Urls.Agents,
    protected: true,
    roles: ['admin']
  },
  {
    component: lazy(() => import('./ClientPage')),
    id: 14,
    layout: LayoutTypes.Base,
    path: Urls.Clients + '/:id',
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./NotificationsPage')),
    id: 15,
    layout: LayoutTypes.Base,
    path: Urls.Notifications,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./CorrectionsPage')),
    id: 16,
    layout: LayoutTypes.Base,
    path: Urls.Corrections,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./CorrectionPage')),
    id: 17,
    layout: LayoutTypes.Base,
    path: Urls.Corrections + '/new',
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./CorrectionPage')),
    id: 18,
    layout: LayoutTypes.Base,
    path: Urls.Corrections + '/:id',
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./DebtorsPage')),
    id: 19,
    layout: LayoutTypes.Base,
    path: Urls.Debtors,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./TransactionsPage')),
    id: 19,
    layout: LayoutTypes.Base,
    path: Urls.Transactions,
    protected: true,
    roles: ['admin', 'manager']
  },
  {
    component: lazy(() => import('./StatisticsPage')),
    id: 20,
    layout: LayoutTypes.Base,
    path: Urls.Statistic,
    protected: true,
    roles: ['admin']
  },
  {
    component: lazy(() => import('./CardStatementPage')),
    id: 20,
    layout: LayoutTypes.Base,
    path: Urls.CardStatement,
    protected: true,
    roles: ['admin']
  },
  {
    component: lazy(() => import('./CardStatementPage')),
    id: 21,
    layout: LayoutTypes.Base,
    path: `${Urls.CardStatement}/:id`,
    protected: true,
    roles: ['admin']
  }
];
