import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getClients = createAction('users/GET_CLIENTS');

const clientsSlice = createSlice({
  initialState: initialStore.clientsPage,
  name: 'clientsPage',
  reducers: {
    removeClient: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setClients: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    updateClient: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const { removeClient, setClients, setLoading, updateClient } =
  clientsSlice.actions;

export const { reducer } = clientsSlice;
