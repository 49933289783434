import { fork } from 'redux-saga/effects';

import AgentsSaga from './agentPage/saga';
import BankSaga from './banksPage/saga';
import CardsSaga from './cardsPage/saga';
import ClientsSaga from './clientsPage/saga';
import CorrectionsSage from './correctionsPage/saga';
import DebtsSage from './debtorsPage/saga';
import DevicesSaga from './devicesPage/saga';
import HoldersSaga from './holdersPage/saga';
import IncomesSaga from './incomesPage/saga';
import NotificationsSaga from './notificationsPage/saga';
import OperatorsSaga from './operatorsPage/saga';
import OutcomesSaga from './outcomePage/saga';
import TariffSaga from './tariffPage/saga';
import TransactionsSaga from './transactionsPage/saga';
import UserSaga from './userData/saga';
import UsersSaga from './usersPage/saga';

export default function* () {
  yield fork(UserSaga);
  yield fork(BankSaga);
  yield fork(TariffSaga);
  yield fork(DevicesSaga);
  yield fork(AgentsSaga);
  yield fork(HoldersSaga);
  yield fork(CardsSaga);
  yield fork(UsersSaga);
  yield fork(ClientsSaga);
  yield fork(OperatorsSaga);
  yield fork(IncomesSaga);
  yield fork(NotificationsSaga);
  yield fork(OutcomesSaga);
  yield fork(CorrectionsSage);
  yield fork(DebtsSage);
  yield fork(TransactionsSaga);
}
