import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class DeviceService extends BaseService {
  getListWithHolders = (params) => {
    return http({
      params,
      url: `${this.endpoint}/list-with-holders`
    }).then(({ data }) =>
      data.map((el) => {
        const countActiveCards = el.holders?.reduce((acc, item) => {
          return item.countActiveCards + acc;
        }, 0);

        return {
          label: `${el.number} ${el.holders
            .map((item) => item.lastName)
            .join(', ')} (${countActiveCards})`,
          value: el.id,
          ...el
        };
      })
    );
  };

  getOptionsList = (params) => {
    return http({
      params,
      url: `${this.endpoint}/`
    }).then(({ data }) => ({
      next: data.next,
      result: data.result?.map((el) => {
        return {
          label: el.number,
          value: el.id
        };
      })
    }));
  };

  constructor() {
    super('/device');
    if (!DeviceService.instance) {
      DeviceService.instance = this;
    }
    return DeviceService.instance;
  }
}

const instance = new DeviceService();
Object.freeze(instance);

export default instance;
