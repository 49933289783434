import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import ClientService from '#services/ClientService';

import { getClients, setClients, setLoading } from './';

function* getClientsList(data) {
  try {
    yield put(setLoading(true));
    const agents = yield call(ClientService.getList, data?.payload);
    yield put(setClients(agents));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setClients({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* ClientsSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getClients.type, getClientsList);
}

export default ClientsSaga;
