import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class ClientService extends BaseService {
  getClientInfo = async (id) => {
    const { data } = await http({
      url: `${this.endpoint}/${id}`
    });
    return data;
  };

  getOptionsList = (params) => {
    return http({
      params,
      url: `${this.endpoint}/`
    }).then(({ data }) => ({
      next: data.next,
      result: data.result?.map((el) => {
        return {
          label: el.name,
          value: el.id
        };
      })
    }));
  };

  updateProfile = async (id, data) => {
    const { data: result } = await http({
      data,
      method: 'PATCH',
      url: `${this.endpoint}/${id}`
    });
    return result;
  };

  changeBalance = async (id, data) => {
    const { data: result } = await http({
      data,
      method: 'PATCH',
      url: `${this.endpoint}/${id}/change-balance`
    });
    return result;
  };

  getClientProfileTransactions = async (params) => {
    const { data } = await http({
      method: 'GET',
      params,
      url: `/client-profile-transactions`
    });

    return data;
  };

  constructor() {
    super('/client-profile');
    if (!ClientService.instance) {
      ClientService.instance = this;
    }
    return ClientService.instance;
  }
}

const instance = new ClientService();
Object.freeze(instance);

export default instance;
