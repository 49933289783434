import dayjs from 'dayjs';

import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class OutcomeService extends BaseService {
  constructor() {
    super('/outcome-operation');
    if (!OutcomeService.instance) {
      OutcomeService.instance = this;
    }
    return OutcomeService.instance;
  }

  getOutcomeCard = async (id) => {
    const { data } = await http({
      url: `${this.endpoint}/${id}/allowed-cards`
    });
    return data;
  };

  getCalculations = async (id, cards) => {
    const { data } = await http({
      data: { cards },
      method: 'PATCH',
      url: `${this.endpoint}/${id}/cards`
    });

    return data;
  };

  restoreOperation = async (id) => {
    return await http({
      method: 'GET',
      url: `${this.endpoint}/${id}/reopen`
    });
  };

  sendToTelegram = async (id) => {
    return await http({
      method: 'GET',
      url: `${this.endpoint}/${id}/notify-telegram`
    });
  };

  exportToCSV = async (params) => {
    return await http({
      method: 'GET',
      params,
      responseType: 'blob',
      url: `${this.endpoint}/export-csv`
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const fileName = `export-outcome-${dayjs().format(
        'YYYYDDMM-HHmmss'
      )}.csv`;

      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
    });
  };

  transfer = async (params) => {
    return await http({
      data: params,
      method: 'POST',
      url: `${this.endpoint}/transfer`
    });
  };
}

const instance = new OutcomeService();
Object.freeze(instance);

export default instance;
