import { BaseService } from '#services/BaseService';

class AgentService extends BaseService {
  constructor() {
    super('/agent');
    if (!AgentService.instance) {
      AgentService.instance = this;
    }
    return AgentService.instance;
  }
}

const instance = new AgentService();
Object.freeze(instance);

export default instance;
