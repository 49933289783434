import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class CorrectionService extends BaseService {
  constructor() {
    super('/correction-request');
    if (!CorrectionService.instance) {
      CorrectionService.instance = this;
    }
    return CorrectionService.instance;
  }

  getCorrectionInfo = async (id) => {
    const { data } = await http({
      url: `${this.endpoint}/${id}`
    });
    return data;
  };

  approveItems = async (id, data) => {
    await http({
      data,
      method: 'PATCH',
      url: `${this.endpoint}/${id}/approve`
    });
  };

  rejectItems = async (id, data) => {
    await http({
      data,
      method: 'PATCH',
      url: `${this.endpoint}/${id}/reject`
    });
  };
}

const instance = new CorrectionService();
Object.freeze(instance);

export default instance;
