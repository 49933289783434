import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import ManagerService from '#services/OutcomeService';

import { getOutcomes, setLoading, setOutcomes } from './';

function* getOutcomesList(data) {
  try {
    yield put(setLoading(true));
    const result = yield call(ManagerService.getList, data?.payload);
    yield put(setOutcomes(result));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setOutcomes({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* OutcomesSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getOutcomes.type, getOutcomesList);
}

export default OutcomesSaga;
