import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import CardsService from '#services/CardsService';

import { getCards, setCards, setLoading } from './';

function* getCardsList(data) {
  try {
    yield put(setLoading(true));
    const cards = yield call(CardsService.getList, data?.payload);
    yield put(setCards(cards));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setLoading(false));
    yield put(setCards({ count: 0, result: [] }));
  }
}

function* CardsSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getCards.type, getCardsList);
}

export default CardsSaga;
