import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import DeviceService from '#services/DeviceService';

import { getDevices, setDevices, setLoading } from './';

function* getDevicesList(data) {
  try {
    yield put(setLoading(true));
    const devices = yield call(DeviceService.getList, data?.payload);
    yield put(setDevices(devices));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setDevices({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* DevicesSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getDevices.type, getDevicesList);
}

export default DevicesSaga;
