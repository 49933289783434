import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class TariffService extends BaseService {
  getByParams = async (params) => {
    const { data } = await http({
      params,
      url: this.endpoint + '/'
    });
    return data;
  };

  getList = async (params) => {
    const { data } = await http({
      params,
      url: this.endpoint + '/all'
    });
    return data;
  };

  getOptionsByParams = (params) => {
    return http({
      params,
      url: `${this.endpoint}/`
    }).then(({ data }) => ({
      next: false,
      result: data.map((el) => {
        return {
          label: el.name,
          value: el.id
        };
      })
    }));
  };

  constructor() {
    super('/tariff');
    if (!TariffService.instance) {
      TariffService.instance = this;
    }
    return TariffService.instance;
  }
}

const instance = new TariffService();
Object.freeze(instance);

export default instance;
