import { BaseService } from '#services/BaseService';

class OperatorService extends BaseService {
  constructor() {
    super('/operator');
    if (!OperatorService.instance) {
      OperatorService.instance = this;
    }
    return OperatorService.instance;
  }
}

const instance = new OperatorService();
Object.freeze(instance);

export default instance;
