import { BaseService } from '#services/BaseService';
import { http } from '#utils/axios';

class HolderService extends BaseService {
  getOptionsList = (params) => {
    return http({
      params,
      url: `${this.endpoint}/`
    }).then(({ data }) => ({
      next: data.next,
      result: data.result?.map((el) => {
        return {
          label: `${el.lastName} ${el.firstName}`,
          value: el.id
        };
      })
    }));
  };

  constructor() {
    super('/holder');
    if (!HolderService.instance) {
      HolderService.instance = this;
    }
    return HolderService.instance;
  }
}

const instance = new HolderService();
Object.freeze(instance);

export default instance;
