import { notification } from 'antd';
import { call, debounce, put, takeLatest } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import BankService from '#services/BankService';

import {
  createBank as createBankAction,
  deleteBank as deleteBankAction,
  editBank as editBankAction,
  getBanks as getBanksAction,
  getBanksError,
  setBanks,
  updateBank
} from './';

function* getBanks({ payload }) {
  try {
    const banks = yield call(BankService.getBanks, payload);
    yield put(setBanks(banks));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setBanks({ count: 0, result: [] }));
    yield put(getBanksError());
  }
}

function* createBank({ payload }) {
  try {
    yield call(BankService.createBank, payload);
    yield getBanks({ payload: { page: 1, search: '' } });
  } catch (error) {
    console.log('createBank error:', error);
  }
}

function* deleteBank({ payload }) {
  try {
    yield call(BankService.deleteBank, payload.id);
    yield getBanks(payload.pagination);
  } catch (error) {
    console.log('deleteBank error:', error);
  }
}

function* editBank({ payload }) {
  try {
    const bank = yield call(BankService.editBank, payload);
    yield put(updateBank(bank));
  } catch (error) {
    console.log('editBank error:', error);
  }
}

function* BankSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getBanksAction.type, getBanks);
  yield takeLatest(createBankAction.type, createBank);
  yield takeLatest(deleteBankAction.type, deleteBank);
  yield takeLatest(editBankAction.type, editBank);
}

export default BankSaga;
