import { App as AntdApp, ConfigProvider } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Layouts } from '#components/Layouts';
import { Loader } from '#components/Loader';
import { Urls } from '#constants';
import { getUser } from '#store/userData';
import { getTokenFromCookies } from '#utils/cookies-actions';

import { ROUTES } from './pages';

const RequireAuth = ({ children, privateRoute, roles }) => {
  const dispatch = useDispatch();
  const { isLoading, user } = useSelector((s) => s.userData);
  const userRole = user?.role;
  const token = getTokenFromCookies();

  useEffect(() => {
    if (privateRoute && token && !user) {
      dispatch(getUser());
    }
  }, [dispatch, privateRoute, token, user]);

  if (privateRoute && !token) {
    return <Navigate to={Urls.LoginPage} />;
  }
  if (privateRoute && token && isLoading) {
    return <Loader isBig />;
  }

  if (privateRoute && !roles?.includes(userRole)) {
    return <Navigate to={Urls.OutcomePage} />;
  }

  return <>{children}</>;
};

export const App = () => (
  <ConfigProvider
    theme={{
      token: {
        Form: {
          itemMarginBottom: 8
        }
      }
    }}
  >
    <AntdApp notification={{ stack: { threshold: 1 } }}>
      <Routes>
        {ROUTES.map((el) => (
          <Route
            element={
              <Suspense
                fallback={
                  <div style={{ height: '100%' }}>
                    <Loader />
                  </div>
                }
              >
                <RequireAuth privateRoute={el.protected} roles={el.roles}>
                  <Layouts layout={el.layout}>
                    <el.component />
                  </Layouts>
                </RequireAuth>
              </Suspense>
            }
            key={el.id}
            path={el.path}
          />
        ))}
        <Route element={<Navigate to={Urls.OutcomePage} />} path="*" />
      </Routes>
    </AntdApp>
  </ConfigProvider>
);
