import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const createTariff = createAction('tariffs/CREATE_TARIFF');
export const editTariff = createAction('tariffs/EDIT_TARIFF');

const tariffsSlice = createSlice({
  initialState: initialStore.tariffsPage,
  name: 'tariffsPage',
  reducers: {
    getTariffs: (state) => {
      state.isLoading = true;
    },
    getTariffsError: (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message || '';
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setTariffs: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    temporaryUpdateTariff: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload
          };
        }
        return item;
      });
    },
    updateTariff: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const { getTariffs, setLoading, setTariffs } = tariffsSlice.actions;

export const { reducer } = tariffsSlice;
