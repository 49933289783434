import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getHolders = createAction('holders/GET_AGENTS');

const holdersSlice = createSlice({
  initialState: initialStore.holdersPage,
  name: 'holdersPage',
  reducers: {
    removeHolders: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setHolders: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    updateHolders: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const { removeHolders, setHolders, setLoading, updateHolders } =
  holdersSlice.actions;

export const { reducer } = holdersSlice;
