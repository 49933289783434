import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const createBank = createAction('banks/CREATE_BANK');
export const editBank = createAction('banks/EDIT_BANK');

const banksSlice = createSlice({
  initialState: initialStore.banksPage,
  name: 'banksPage',
  reducers: {
    deleteBank: (state) => {
      state.isLoading = true;
    },
    getBanks: (state) => {
      state.isLoading = true;
    },
    getBanksError: (state) => {
      state.isLoading = false;
    },
    setBanks: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    temporaryUpdateBank: (state, { payload }) => {
      state.banks = state.banks.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload
          };
        }
        return item;
      });
    },
    updateBank: (state, { payload }) => {
      state.banks = state.banks.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});
export const {
  deleteBank,
  getBanks,
  getBanksError,
  setBanks,
  temporaryUpdateBank,
  updateBank
} = banksSlice.actions;

export const { reducer } = banksSlice;
