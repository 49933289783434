import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getTransactions = createAction('operators/GET_TRANSACTIONS');

const transactionsSlice = createSlice({
  initialState: initialStore.operatorsPage,
  name: 'transactionsPage',
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setTransactions: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
      state.isLoading = false;
    }
  }
});

export const { setLoading, setTransactions } = transactionsSlice.actions;

export const { reducer } = transactionsSlice;
