import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getIncomes = createAction('incomes/GET_INCOMES');

const incomesSlice = createSlice({
  initialState: initialStore.incomesPage,
  name: 'incomesPage',
  reducers: {
    removeIncome: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload.id);
    },
    setIncomes: (state, { payload }) => {
      const { count, result } = payload;
      state.data = result;
      state.count = count;
      state.isLoading = false;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    updateIncome: (state, { payload }) => {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    }
  }
});

export const { removeIncome, setIncomes, setLoading, updateIncome } =
  incomesSlice.actions;

export const { reducer } = incomesSlice;
