import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import CorrectionService from '#services/CorrectionService';

import { getCorrections, setCorrections, setLoading } from './';

function* getCorrectionsList(data) {
  try {
    yield put(setLoading(true));
    const agents = yield call(CorrectionService.getList, data?.payload);
    yield put(setCorrections(agents));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setCorrections({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* CorrectionsSaga() {
  yield debounce(
    DEFAULT_DEBOUNCE_DELAY,
    getCorrections.type,
    getCorrectionsList
  );
}

export default CorrectionsSaga;
