import {
  BankOutlined,
  ClusterOutlined,
  CreditCardOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  IssuesCloseOutlined,
  LineChartOutlined,
  LoginOutlined,
  LogoutOutlined,
  NotificationOutlined,
  RetweetOutlined,
  SolutionOutlined,
  SwapOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import { Layout, Menu, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { Urls } from '#constants';

import logoSmall from './logo_small.png';
import logo from './logo.png';

import classes from './styles.module.scss';

const { Sider } = Layout;

export const Sidebar = ({ collapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const userRole = useSelector((state) => state.userData.user.role);
  const isAdmin = userRole === 'admin';

  const getItem = useCallback(
    (label, key, icon, children, type) => ({
      children,
      icon,
      key,
      label,
      type
    }),
    []
  );

  const selectedKey = useMemo(() => {
    const urls = Object.values(Urls).slice(1);

    return (
      urls.findIndex((el) => {
        return location.pathname.includes(el);
      }) + 1 || 1
    );
  }, [location]);

  return (
    <Sider collapsed={collapsed} collapsible trigger={null}>
      <NavLink to={Urls.OutcomePage}>
        <div className={classes.logo}>
          <img src={logoSmall} />
        </div>
      </NavLink>

      <Menu
        items={[
          getItem(
            <NavLink to={Urls.OutcomePage}>{t('Payouts')}</NavLink>,
            '1',
            <LogoutOutlined />
          ),
          getItem(
            <NavLink to={Urls.IncomePage}>{t('Receipts')}</NavLink>,
            '2',
            <LoginOutlined />
          ),
          isAdmin && [
            getItem(
              <NavLink to={Urls.UsersPage}>{t('Users')}</NavLink>,
              '3',
              <TeamOutlined />
            )
          ],
          getItem(
            <NavLink to={Urls.Clients}>{t('Clients')}</NavLink>,
            '4',
            <UserOutlined />
          ),
          isAdmin && [
            getItem(
              <NavLink to={Urls.Operators}>{t('Operators')}</NavLink>,
              '5',
              <UserSwitchOutlined />
            )
          ],
          getItem(
            <NavLink to={Urls.Cards}>{t('Cards')}</NavLink>,
            '6',
            <CreditCardOutlined />
          ),
          getItem(
            <NavLink to={Urls.Tariffs}>{t('Tariffs')}</NavLink>,
            '7',
            <DollarOutlined />
          ),
          getItem(
            <NavLink to={Urls.Banks}>{t('Banks')}</NavLink>,
            '8',
            <BankOutlined />
          ),
          getItem(
            <NavLink to={Urls.Devices}>{t('Devices')}</NavLink>,
            '9',
            <DeploymentUnitOutlined />
          ),
          isAdmin && [
            getItem(
              <NavLink to={Urls.Holders}>{t('Holders')}</NavLink>,
              '10',
              <SolutionOutlined />
            )
          ],
          isAdmin && [
            getItem(
              <NavLink to={Urls.Agents}>{t('Agents')}</NavLink>,
              '11',
              <ClusterOutlined />
            )
          ],
          getItem(
            <NavLink to={Urls.Notifications}>{t('Notifications')}</NavLink>,
            '12',
            <NotificationOutlined />
          ),
          getItem(
            <NavLink to={Urls.Corrections}>{t('Balance corrections')}</NavLink>,
            '13',
            <RetweetOutlined />
          ),
          getItem(
            <NavLink to={Urls.Debtors}>{t('Debtors')}</NavLink>,
            '14',
            <IssuesCloseOutlined />
          ),
          getItem(
            <NavLink to={Urls.Transactions}>{t('Transactions')}</NavLink>,
            '15',
            <SwapOutlined />
          ),
          isAdmin && [
            getItem(
              <NavLink to={Urls.Statistic}>{t('Statistics')}</NavLink>,
              '16',
              <LineChartOutlined />
            )
          ],
          isAdmin && [
            getItem(
              <NavLink to={Urls.CardStatement}>{t('Card statement')}</NavLink>,
              '17',
              <LineChartOutlined />
            )
          ]
        ].flat()}
        mode="inline"
        selectedKeys={[selectedKey.toString()]}
        theme="dark"
      />
    </Sider>
  );
};
