import { CheckCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { App, Avatar, Dropdown, Space } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import OperatorService from '#services/OperatorService';
import UserService from '#services/UserService';
import { updateUser } from '#store/userData';
import {
  getOperatorStatus,
  operatorStatuses
} from '#utils/get-operator-status';

function getItem(label, key, icon, children, type) {
  return {
    children,
    icon,
    key,
    label,
    type
  };
}

const STATUS_PREFIX = 'status_';

export const UserMenu = () => {
  const { notification } = App.useApp();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userData);

  const items = useMemo(() => {
    let itemsArr = [];

    if ('operator' in user) {
      itemsArr.push(
        getItem(t('Work direction'), 'change_status', null, [
          getItem(
            getOperatorStatus(operatorStatuses.All, t),
            STATUS_PREFIX + operatorStatuses.All,

            <span>
              {user.operator.status === operatorStatuses.All ? (
                <CheckCircleTwoTone twoToneColor="#2371d7" />
              ) : null}
            </span>
          ),
          getItem(
            getOperatorStatus(operatorStatuses.Income, t),
            STATUS_PREFIX + operatorStatuses.Income,
            <span>
              {user.operator.status === operatorStatuses.Income ? (
                <CheckCircleTwoTone twoToneColor="#2371d7" />
              ) : null}
            </span>
          ),
          getItem(
            getOperatorStatus(operatorStatuses.Outcome, t),
            STATUS_PREFIX + operatorStatuses.Outcome,
            <span>
              {user.operator.status === operatorStatuses.Outcome ? (
                <CheckCircleTwoTone twoToneColor="#2371d7" />
              ) : null}
            </span>
          ),
          getItem(
            t('Turn off all'),
            STATUS_PREFIX + operatorStatuses.None,
            <span>
              {user.operator.status === operatorStatuses.None ? (
                <CheckCircleTwoTone twoToneColor="#2371d7" />
              ) : null}
            </span>
          )
        ])
      );
    }

    itemsArr.push(getItem(t('Logout'), 'logout', null, null, 'item'));

    return itemsArr;
  }, [user, t]);

  const updateStatus = async (status) => {
    try {
      await OperatorService.editItem({ id: user.operator.id, status });

      dispatch(updateUser({ ...user, operator: { ...user.operator, status } }));

      notification.success({
        message: t('Success')
      });
    } catch (e) {
      notification.error({
        description: e.response?.data?.error?.message || t('Unable to update'),
        message: t('Error')
      });
    }
  };

  const onItemClick = (e) => {
    if (e.key === 'logout') {
      UserService.logout();
    }

    if (e.key.includes(STATUS_PREFIX)) {
      const status = e.key.replace(STATUS_PREFIX, '');
      updateStatus(status);
    }
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <Dropdown
        arrow={{
          pointAtCenter: true
        }}
        menu={{ items: items, onClick: onItemClick }}
        mode="vertical"
        placement="bottomRight"
        trigger={['click']}
      >
        <Space>
          <Avatar
            icon={<UserOutlined />}
            style={{ backgroundColor: '#87d068' }}
          />
        </Space>
      </Dropdown>
    </div>
  );
};
