export const modelsNamesForComments = {
  agent: 'agent',
  bank: 'bank',
  blackList: 'blackList',
  card: 'card',
  clientProfile: 'clientProfile',
  clientProfileTransaction: 'clientProfileTransaction', // TODO: check this
  correctionRequest: 'correctionRequest',
  correctionRequestRecord: 'correctionRequestRecord',
  debtor: 'debtor',
  device: 'device',
  deviceNotification: 'deviceNotification',
  holder: 'holder',
  incomeOperation: 'incomeOperation',
  operator: 'operator',
  outcomeOperation: 'outcomeOperation',
  tariff: 'tariff',
  user: 'user'
};
