import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import NotificationService from '#services/NotificationService';

import { getNotifications, setLoading, setNotifications } from './';

function* getNotificationsList(data) {
  try {
    yield put(setLoading(true));
    const notifications = yield call(
      NotificationService.getList,
      data?.payload
    );
    yield put(setNotifications(notifications));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setNotifications({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* NotificationsSaga() {
  yield debounce(
    DEFAULT_DEBOUNCE_DELAY,
    getNotifications.type,
    getNotificationsList
  );
}

export default NotificationsSaga;
