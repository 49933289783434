const defaultState = {
  count: 0,
  data: [],
  isLoading: true
};

export const initialStore = {
  agentsPage: defaultState,
  banksPage: defaultState,
  cardsPage: defaultState,
  clientsPage: defaultState,
  correctionsPage: defaultState,
  debtorsPage: defaultState,
  devicesPage: defaultState,
  holdersPage: defaultState,
  incomesPage: defaultState,
  notificationsPage: defaultState,
  operatorsPage: defaultState,
  outcomesPage: defaultState,
  tariffsPage: defaultState,
  transactionsPage: defaultState,
  userData: {
    isLoading: true,
    user: null
  },
  usersPage: defaultState
};
