import { notification } from 'antd';
import { call, debounce, put } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_DELAY } from '#constants';
import ManagerService from '#services/OperatorService';

import { getOperators, setLoading, setOperators } from './';

function* getOperatorsList(data) {
  try {
    yield put(setLoading(true));
    const agents = yield call(ManagerService.getList, data?.payload);
    yield put(setOperators(agents));
  } catch (error) {
    notification.error({ message: error?.response?.data?.error?.message });
    yield put(setOperators({ count: 0, result: [] }));
    yield put(setLoading(false));
  }
}

function* OperatorsSaga() {
  yield debounce(DEFAULT_DEBOUNCE_DELAY, getOperators.type, getOperatorsList);
}

export default OperatorsSaga;
