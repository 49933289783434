import { BaseService } from '#services/BaseService';

class DebtorsService extends BaseService {
  constructor() {
    super('/debtor');
    if (!DebtorsService.instance) {
      DebtorsService.instance = this;
    }
    return DebtorsService.instance;
  }
}

const instance = new DebtorsService();
Object.freeze(instance);

export default instance;
